<template>
  <dialog-product-tour visible />
</template>

<script>
import DialogProductTour from '@/views/SelectIdea/Dialogs/DialogProductTour'

export default {
  name: 'ProductTour',

  components: {
    DialogProductTour
  }
}
</script>
